import React, {useEffect, useState} from 'react'
import {Header} from "../../../../header/Header";
import {FacilitatorHeader} from "../FacilitatorHeader";
import {Modal} from "react-bootstrap";
import {zynity_api, zynity_website} from "../../../../../config/external-routes";
import DataTable from "react-data-table-component";
import {useParams} from "react-router-dom";
import axios from "axios";
import FilterComponent from "../../../../ui/FilterComponent";
import {CoachNameColumn} from "../../../admin/coaches/CoachNameColumn";
import {AssignOrgCoachColumn} from "../../../admin/coaches/AssignOrgCoachColumn";
import {useDispatch, useSelector} from "react-redux";
import Swal from 'sweetalert2'
import {Loading} from "../../../../ui/loading/Loading";
import {getOrganizationTeam} from "../../../../../store/user_organizations/teams/team";

export const ManageCoaches = () => {
    const dispatch = useDispatch();
    const router = useSelector((state:any) => state.router);
    const organization_id = router.selected_organization_id;
    const team_id = router.selected_team_id;
    const organization_team = useSelector((state:any) => state.organization_team);

    const [data, setData] = useState([]);
    const [loadingCoachFullList, setLoadingCoachFullList] = useState(false);
    const [totalRowsCoachFullList, setTotalRowsCoachFullList] = useState(0);
    const [perPageCoachFullList, setPerPageCoachFullList] = useState(7);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageCoachFullList, setCurrentPageCoachFullList] = useState(1);
    const [filterText, setFilterText] = React.useState(null);
    const [filterTextCoachFullList, setFilterTextCoachFullList] = React.useState(null);

    const [fullListCoaches, setFullListCoaches] = useState([]);
    const [showFullListCoachesModal, setShowFullListCoachesModal] = useState(false);

    let org_team_url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
    const coaches = organization_team.data.coaches
    let organization_coaches_url = zynity_api.organization_coaches.replace('{organization_id}', organization_id);

    useEffect(() => {
        if(organization_team.isLoading == false) {
            fetchFullListCoaches(1)
        }
    }, [organization_team]);

    useEffect(() => {
        if(filterTextCoachFullList !== null) {
            const timeOutId = setTimeout(() => fetchFullListCoaches(1), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [filterTextCoachFullList]);

    const fetchFullListCoaches = async (page: any) => {
        setLoadingCoachFullList(true);
        let filter = filterTextCoachFullList !== null && filterTextCoachFullList !== '' ? `&search_text=${filterTextCoachFullList}` : '';
        const response = await axios.get(`${organization_coaches_url}?page=${page}&limit=${perPageCoachFullList}${filter}`);
        setFullListCoaches(response.data.data);
        setTotalRowsCoachFullList(response.data.total);
        setLoadingCoachFullList(false);
    }

    const handlePageChangeCoachFullList = (page: any) => {
        fetchFullListCoaches(page);
    };

    const handlePerRowsChangeCoachFullList = async (newPerPage: React.SetStateAction<number>, page: React.SetStateAction<number>) => {
        setLoadingCoachFullList(true);
        setCurrentPageCoachFullList(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        const response = await axios.get(`${organization_coaches_url}?page=${page}&limit=${perPageCoachFullList}${filter}`);

        setFullListCoaches(response.data.data);
        setPerPageCoachFullList(newPerPage);
        setLoadingCoachFullList(false);
    };

    const subHeaderComponentMemoCoachFullList = React.useMemo(() => {
        const handleClear = () => {
            setFilterTextCoachFullList('');
        };

        const handleFilter = (e: { target: { value: any; }; }) => {
            setFilterTextCoachFullList(e.target.value)
        }

        return (
            <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterTextCoachFullList} />
        );
    }, [filterTextCoachFullList]);

    const columns = [
        {
            name: 'Name',
            cell: (row: any) => <CoachNameColumn row={row} organization_id={organization_id} />
        },
        {
            name: 'Email',
            cell: (row: any) => row.Email
        },
        {
            name: 'Phone',
            cell: (row: any) => row.Phone,
            allowOverflow: true
        },
        {
            name: 'Action',
            cell: (row: any) => <button type="button" className="btn btn-teal btn-sm"
                                        onClick={() => removeOrgCoach(row.id, `${row.FirstName} ${row.LastName}`)}>Remove</button>
        },
    ];

    const columns_full_coaches_list = [
        {
            name: 'Name',
            cell: (row: any) => <CoachNameColumn row={row} organization_id={organization_id} />
        },
        {
            name: 'Email',
            cell: (row: any) => row.Email
        },
        {
            name: 'Action',
            cell: (row: any) => <AssignOrgCoachColumn row={row} organization_coaches_list={coaches} assignOrgCoachMethod={assignOrgCoach} organization_id={organization_id} />
        },
    ];

    if(organization_team.isLoading) {
        return (
            <>
                <Header />
                <Loading />
            </>
        )
    }

    const removeOrgCoach = (coach_id: any, coach_name: any) =>
    {
        console.log("going to delete: ",coach_id )
        Swal.fire({
            title: `Confirm Removing ${coach_name}`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Deleted!', '', 'success')
                const response = axios.delete(`${org_team_url}/facilitator_dashboard/coaches/${coach_id}`);
                response.then((success) => {
                    dispatch( getOrganizationTeam({organization_id, team_id}) );
                });
            }
        })
    }

    const assignOrgCoach = (coach_id: any) =>
    {
        document.getElementById(`button_assign_coach_${coach_id}`).innerHTML = "Adding...";
        const response = axios.post(`${org_team_url}/facilitator_dashboard/coaches/${coach_id}`);
        response.then((success) => {
            Swal.fire('Coach Added!', '', 'success')
            dispatch( getOrganizationTeam({organization_id, team_id}) );
            handleClose()
        });
    }

    const handleClose = () => setShowFullListCoachesModal(false);

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-2 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <FacilitatorHeader page_name={'Manage Coaches'} />
                        </div>
                    </div>

                    <div className="col-12 col-md-3 align-self-center text-end">
                        <button type="button" className="btn btn-primary" onClick={() => setShowFullListCoachesModal(true)}>Add Coach</button>

                        <Modal show={showFullListCoachesModal} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Assign Certified Coach</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="text-muted">Complete coach profiles are available at <a target='_blank' href={`${zynity_website.public}/coachList`}>Zynity Certified Coaches</a>.</div>
                                <div className="text-muted">Contact the Org Administrator if the coach you want to add is not on this list.</div>
                                <div className="row">
                                    <div className="col-12 data-table-container">
                                        <DataTable
                                            columns={columns_full_coaches_list}
                                            data={fullListCoaches}
                                            progressPending={loadingCoachFullList}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRowsCoachFullList}
                                            onChangeRowsPerPage={handlePerRowsChangeCoachFullList}
                                            onChangePage={handlePageChangeCoachFullList}
                                            paginationPerPage={perPageCoachFullList}
                                            subHeader
                                            subHeaderComponent={subHeaderComponentMemoCoachFullList}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div className="col-12 data-table-container">
                        <DataTable
                            className='datatable_no_overflow'
                            columns={columns}
                            data={coaches}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
